<template>
  <section class="main-body">
    <h3 class="subheading">Promo Config</h3>

    <section class="section-container">
      <v-layout v-if="!newItem" row>
        <v-flex mr-5 class="half">
          <label class="label" for="promoId">Promo ID</label>
          <v-text-field
            id="promoId"
            v-model="overlayPromo.promoID"
            disabled
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Unique promo ID.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row class="published-wrap">
        <v-flex mr-5 class="half">
          <div class="published-row">
            <label class="label published-row__label" for="published">Published</label>
            <v-checkbox
              id="published"
              v-model="overlayPromo.published"
              :false-value=0
              :true-value=1
              @change="updateData()"
            ></v-checkbox>
          </div>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Enable to publish the promo. If published the promo will only be seen by users within its Published From/Until dates below. If left unpublished the promo will not be seen, even if within its Published From/Until dates.</i></p>
          <p><i>Unpublished promos can still be saved for later.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="startDate">Published From*</label>
          <v-text-field
            id="startDate"
            v-model="overlayPromo.startDateTime"
            placeholder="YYYY-MM-DD hh:mm"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - Enter the date and time that the promo will become active. This MUST be entered in the format 'YYYY-MM-DD hh:mm' (e.g. '2021-01-29 19:30').</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="endDate">Published Until*</label>
          <v-text-field
            id="endDate"
            v-model="overlayPromo.endDateTime"
            placeholder="YYYY-MM-DD hh:mm"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - Enter the date and time that the promo will become active. This MUST be entered in the format 'YYYY-MM-DD hh:mm' (e.g. '2021-01-29 19:30').</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="channels">Channels*</label>
          <v-select
            id="channels"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Web', value: ['Web'] },
              { name: 'App', value: ['App'] },
              { name: 'Web & App', value: ['Web', 'App'] }
            ]"
            v-model="overlayPromo.channels"
            solo
            flat
            @change="updateData()"
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - Select whether you would like the promo to be seen on web, on the app, or both.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="campaignID">Campaign ID</label>
          <v-text-field
            id="campaignID"
            v-model="overlayPromo.campaignID"
            disabled
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Not currently in use.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Promo Details</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="promoTitle">Title*</label>
          <v-text-field
            id="promoTitle"
            v-model="overlayPromo.title"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - Name of promo. This will be displayed in the admin but will not be seen by users.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="type">Type*</label>
          <v-select
            id="type"
            :items="['Default', 'Large', 'Mini']"
            v-model="overlayPromo.type"
            solo
            flat
            @change="updateData()"
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - Choose the desired display style of promo.</i></p>
          <p><i>Please not that this choice will not affect promos displayed in the app which will always use the default style.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="promoUrl">Promo URL (optional)</label>
          <v-text-field
            id="promoUrl"
            v-model="overlayPromo.url"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Enter a URL to direct users to when the promo is clicked. The whole url must be entered even if it is to an internal page on your site (e.g. 'https://wwww.google.com or https://wwww.myclubwebsite.com/teams).</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="openNewWindow">Open New Tab (optional)</label>
          <v-checkbox
            id="openNewWindow"
            v-model="overlayPromo.webNewWindow"
            :false-value=0
            :true-value=1
            :disabled="!overlayPromo.url"
            @change="updateData()"
          ></v-checkbox>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Enable to allow the Promo URL to open in a new tab when clicked (disabled if no URL is entered).</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Promo Image</h3>
    <section class="section-container">
      <ImageUploader :title="'Promo Image*'" :widthOptionEnabled="false" :mediaLibraryID="overlayPromo.imageID" @update="updateImage($event)" :desc="'<p>*Required. Recommended size: 800px width by 600px height</p>'"/>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="imageAltText">Image Alt Text*</label>
          <v-text-field
            id="imageAltText"
            v-model="overlayPromo.imageAltText"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - Short description of image used for accessibility purposes.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <v-btn v-if="!newItem" color="error" @click="deleteOverlayPromo()">Delete</v-btn>

    <StickyFooter v-if="newItem" :errorMessage="errorMessage" :status="status" @buttonClicked="newOverlayPromo()" />
    <StickyFooter v-else :errorMessage="errorMessage" :status="status" @buttonClicked="saveOverlayPromo()" />
  </section>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"
import StickyFooter from "@/components/Library/StickyFooter"
import moment from "moment"

export default {
  name: "OverlayPromo",

  components: {
    ImageUploader,
    StickyFooter
  },

  data: () => ({
    api: `${process.env.VUE_APP_OVERLAYSAPI}/promo-overlays`,
    status: "saved",
    errorMessage: "",
    newItem: false,
    overlayPromo: {
      published: 0,
      forcedOrder: 0,
      startDateTime: "",
      endDateTime: "",
      channels: [],
      campaignID: "",
      title: "",
      type: "Default",
      url: "",
      webNewWindow: 0,
      imageID: "",
      imageKey: "",
      imageAltText: "",
    }
  }),

  mounted() {
    console.log("Overlay promo mounted");

    if (this.$route.params.id === "create") {
      this.newItem = true
    } else {
      this.fetchOverlayPromo();
    }
  },

  methods: {
    updateData() {
      this.status = "needsSaving";
    },

    fetchOverlayPromo() {
      this.status = "saving";
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/getsingle?promoID=${this.$route.params.id}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchOverlayPromo: ", response);
          this.overlayPromo = response.data.body;
          this.overlayPromo.startDateTime = moment(this.overlayPromo.startDateTime).format("YYYY-MM-DD HH:mm")
          this.overlayPromo.endDateTime = moment(this.overlayPromo.endDateTime).format("YYYY-MM-DD HH:mm")

          this.status = "saved";

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving overlay promo", e);
          this.status = "needsSaving";
          this.$store.commit("completeLoading");
        });
      })
    },

    saveOverlayPromo() {
      if (!this.overlayPromo.startDateTime || !this.overlayPromo.endDateTime || this.overlayPromo.channels.length == 0 || !this.overlayPromo.title || !this.overlayPromo.type || !this.overlayPromo.imageKey || !this.overlayPromo.imageAltText) {

        window.alert("Couldn't save - Check your required fields")

      } else if (this.overlayPromo.endDateTime <= this.overlayPromo.startDateTime) {

        window.alert("Couldn't save - Your 'Published From' data/time is after your 'Published Until' date/time.")

      } else {

        this.$store.commit("startLoading");
        this.overlayPromo.startDateTime = moment(this.overlayPromo.startDateTime).format()
        this.overlayPromo.endDateTime = moment(this.overlayPromo.endDateTime).format()

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "PUT",
            url: `${this.api}/update`,
            headers: { "Authorization": jwt },
            data: this.overlayPromo
          })
          .then(response => {
            console.log("Overlay promo updated", response)
            this.fetchOverlayPromo()
            this.$store.commit("completeLoading");
          })
          .catch(e => {
            console.error("Problem updating overlay promo", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem updating overlay promo";
            this.$store.commit("completeLoading");
            this.overlayPromo.startDateTime = moment(this.overlayPromo.startDateTime).format("YYYY-MM-DD HH:mm")
            this.overlayPromo.endDateTime = moment(this.overlayPromo.endDateTime).format("YYYY-MM-DD HH:mm")
          });
        })

      }
    },

    newOverlayPromo() {
      if (!this.overlayPromo.startDateTime || !this.overlayPromo.endDateTime || this.overlayPromo.channels.length == 0 || !this.overlayPromo.title || !this.overlayPromo.type || !this.overlayPromo.imageKey || !this.overlayPromo.imageAltText) {

        window.alert("Couldn't save - Check your required fields")

      } else if (this.overlayPromo.endDateTime <= this.overlayPromo.startDateTime) {

        window.alert("Couldn't save - Your 'Published From' data/time is after your 'Published Until' date/time.")

      } else {

        this.$store.commit("startLoading");
        this.overlayPromo.startDateTime = moment(this.overlayPromo.startDateTime).format()
        this.overlayPromo.endDateTime = moment(this.overlayPromo.endDateTime).format()

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${this.api}/add`,
            headers: { "Authorization": jwt },
            data: this.overlayPromo
          })
          .then(response => {
            console.log("Overlay promo added", response)
            this.newItem = false
            this.$router.push({ path: "/overlay-promos" })
          })
          .catch(e => {
            console.error("Problem adding overlay promo", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem adding overlay promo";
            this.$store.commit("completeLoading");
            this.overlayPromo.startDateTime = moment(this.overlayPromo.startDateTime).format("YYYY-MM-DD HH:mm")
            this.overlayPromo.endDateTime = moment(this.overlayPromo.endDateTime).format("YYYY-MM-DD HH:mm")
          });
        })

      }
    },

    deleteOverlayPromo() {
      if (confirm("Are you sure you want to delete this?")) {
        console.warn("Deleting")
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${this.api}/delete?promoID=${this.$route.params.id}`,
            headers: { "Authorization": jwt },
          })
          .then(response => {
            console.log("Overlay promo deleted", response);
            this.$router.push({ path: "/overlay-promos" })
          })
          .catch(e => {
            console.error("Problem deleting overlay promo", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem deleting overlay promo";
          });
        })
      }
    },

    updateImage(payload) {
      console.log("updateImage", payload)
      this.status = "needsSaving";
      this.overlayPromo.imageID = payload.mediaLibraryID
      this.overlayPromo.imageKey = payload.imageKey
    }
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }
</style>
